.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.css-fix {
  white-space: pre-wrap; /* or pre-line */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tabs{
  font-family:arial;
  margin:0 auto;
  width:70%;
  text-align:center;
  margin-top:15vh;
}

.tab-buttons{
  margin-bottom:50px;
}

button{
  margin-right:15px;
  font-size:20px;
  background:transparent;
  border:none;
  outline:none;
  padding:10px 20px ;
  cursor:pointer;
  color:rgba(0,0,0,.4);
  transition:all ease-in-out .2s;
  border-bottom:2px solid transparent;
  
}
.active{
  border-bottom:2px solid #42b3f4;
 
  color:black;
}



.is-animate {
  box-sizing: border-box;
  font-size: 66px;
  display: -webkit-inline-box;
  padding: 14px;
  border-radius: 7px;
}
.is-animate > div {
  animation-name: style;
  display: -webkit-inline-box;
  color: #fff;
  padding: 9px;
  background: #42b3f4;
  font-family: 'Indie Flower', cursive;
  box-shadow: 2px 2px 9px 2px;
}
.l{
animation: letterspacing 1s infinite alternate cubic-bezier(.2, 0, 0, 1);
}

.is-animate > div {
animation-duration: 1s;
animation-fill-mode: both;
animation-iteration-count: infinite;
}

.is-animate > div:nth-child(1) { animation-delay: 0.0s }
.is-animate > div:nth-child(2) { animation-delay: 0.1s }
.is-animate > div:nth-child(3) { animation-delay: 0.2s }
.is-animate > div:nth-child(4) { animation-delay: 0.3s }
.is-animate > div:nth-child(5) { animation-delay: 0.4s }
.is-animate > div:nth-child(6) { animation-delay: 0.5s }
.is-animate > div:nth-child(7) { animation-delay: 0.6s }


@keyframes style {
from {
  transform: scale3d(1, 1, 1);
}
30% {
  box-shadow: 0px 0px 0px 0px;
  transform: scale3d(1.25, 0.75, 1);
}
40% {
  transform: scale3d(0.75, 1.25, 1);
}
50% {
  transform: scale3d(1.15, 0.85, 1);
}
65% {
  transform: scale3d(.95, 1.05, 1);
}
75% {
  transform: scale3d(1.05, .95, 1);
}
to {
  transform: scale3d(1, 1, 1);
}
}


@keyframes letterspacing {
0% {
  filter: blur(0.1rem);
}


100% {
  filter: blur(0.5rem);
}
  to {
     letter-spacing: none;
      filter: blur(0rem);
}
}
